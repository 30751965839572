import axios from 'axios';

const API_URL = 'https://moneyapi.edwarhercules.site/api/cuentas'; // Reemplaza con la URL de tu backend
// const API_URL = 'http://localhost:8081/api/cuentas';

export const obtenerCuentasPorCliente = (token) => {
  return fetch(`${API_URL}/porCliente`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      }
  }).then(response => {
      if (response.ok) {
          return response.json();
      } else {
          throw new Error('Error al obtener las cuentas');
      }
  });
};

export const obtenerCuentaPorId = async  (id ,token) => {
  try {
    const response = await axios.get(`${API_URL}/${id}` , {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data
  } catch (error){
    console.error('Error al obtener la cuenta', error);
    throw error;
  }
};

export const getHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  }
});

export const crearCuenta = async (email, cuentaDTO, token) => {
  try {
    const response = await axios.post(`${API_URL}?email=${email}`, cuentaDTO, getHeaders(token));
    return response.data;
  } catch (error) {
    throw error.response.data || 'Error al crear la cuenta';
  }
};

export const obtenerCuentas = async (email, token) => {
  try {
    const response = await axios.get(`${API_URL}?email=${email}`, getHeaders(token));
    return response.data;
  } catch (error) {
    throw error.response.data || 'Error al obtener las cuentas';
  }
};

export const actualizarCuenta = async (id, cuentaDTO, token) => {
  try {
    const response = await axios.put(`${API_URL}/${id}`, cuentaDTO, getHeaders(token));
    return response.data;
  } catch (error) {
    throw error.response.data || 'Error al actualizar la cuenta';
  }
};

export const eliminarCuenta = async (id, token) => {
  try {
      await axios.delete(`${API_URL}/${id}`, {
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });
  } catch (error) {
      throw error.response.data || 'Error al eliminar la cuenta';
  }
};

export default {
  crearCuenta,
  obtenerCuentas,
  actualizarCuenta,
  eliminarCuenta,
};
