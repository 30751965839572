import React, { useState, useEffect } from 'react';
import { useAuth } from '../components/auth/AuthContext';
import { obtenerNombrePersona, obtenerCuentas } from "../api";
import { getUltimosMovimientos } from '../components/Movimientos/MovimientoService';
import { obtenerCuentaPorId } from '../components/Cuentas/CuentaService';
import { Link } from 'react-router-dom';
import PieChartComponent from '../components/Charts/PieChartComponent';
import LineChartComponent from '../components/Charts/LineChartComponent';
import { format } from 'date-fns'; // Importa la función de formateo
import '../components/Styles/Home.css'; // Importa el CSS

const Home = () => {
  const { authData } = useAuth();
  const [nombrePersona, setNombrePersona] = useState('');
  const [cuentas, setCuentas] = useState([]);
  const [selectedCuenta, setSelectedCuenta] = useState('');
  const [movimientos, setMovimientos] = useState([]);
  const [cuentaInfo, setCuentaInfo] = useState(null);

  useEffect(() => {
    if (authData.token && authData.nombre) {
      obtenerNombrePersona(authData.nombre, authData.token)
        .then(nombre => {
          setNombrePersona(nombre);
          return obtenerCuentas(authData.nombre, authData.token);
        })
        .then(cuentas => {
          setCuentas(cuentas);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }, [authData]);

  useEffect(() => {
    if (selectedCuenta && authData.token) {
      obtenerCuentaPorId(selectedCuenta, authData.token)
        .then(cuenta => {
          setCuentaInfo(cuenta);
          return getUltimosMovimientos(selectedCuenta, authData.token);
        })
        .then(movimientos => {
          setMovimientos(movimientos);
        })
        .catch(error => {
          console.error('Error al obtener la cuenta o los movimientos:', error);
        });
    }
  }, [selectedCuenta, authData.token]);

  const handleCuentaChange = (event) => {
    setSelectedCuenta(event.target.value);
  };

  const formatFecha = (fecha) => {
    // Asegúrate de que `fecha` es una cadena en formato ISO o un objeto Date
    return format(new Date(fecha), 'yyyy-MM-dd HH:mm:ss'); // Formato deseado
  };

  return (
    <div className="home-container">
  {authData.token ? (
    <div className="main-content">
      <h2>Bienvenido a tu Control de gastos</h2>
      <h2>{nombrePersona}</h2>
      <h3>Seleccione una cuenta</h3>
      <select  
        onChange={handleCuentaChange} 
        value={selectedCuenta}  
        className="select"
        id='select-options'
      >
        <option value="">Selecciona una cuenta</option>
        {cuentas.map(cuenta => (
          <option key={cuenta.id} value={cuenta.id}>{cuenta.numeroCuenta} - {cuenta.tipoCuenta}</option>
        ))}
      </select>
      <br />
      <h3>Si no tiene una cuenta, cree una aqui</h3>
      <Link to="/crear-cuenta">
        <button className="button-new-account">Crear Nueva Cuenta</button>
      </Link>
      <br />
      {selectedCuenta && cuentaInfo && (
        <div className="account-info">
          <div className='account-info-user'>
            <h3>Información de la Cuenta</h3>
            <div className="info-details">
              <p><strong>Número de Cuenta:</strong> {cuentaInfo.numeroCuenta}</p>
              <p><strong>Banco:</strong> {cuentaInfo.banco}</p>
              <p><strong>Tipo:</strong> {cuentaInfo.tipoCuenta}</p>
              <p><strong>Saldo:</strong> {cuentaInfo.saldoInicial}</p>
              <p><strong>Estado:</strong> {cuentaInfo.estado ? 'Activo' : 'Inactivo'}</p>
            </div>
          </div>
          <h3>Últimos Movimientos</h3>
          {movimientos.length > 0 ? (
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Cliente</th>
                    <th>Numero Cuenta</th>
                    <th>Tipo</th>
                    <th>Estado</th>
                    <th>Movimiento</th>
                    <th>Saldo</th>
                  </tr>
                </thead>
                <tbody>
                  {movimientos.map(movimiento => (
                    <tr key={movimiento.id}>
                      <td>{formatFecha(movimiento.fecha)}</td>
                      <td>{movimiento.cliente}</td>
                      <td>{movimiento.numeroCuenta}</td>
                      <td>{movimiento.tipo}</td>
                      <td>{movimiento.estado ? 'Activo' : 'Inactivo'}</td>
                      <td>{movimiento.movimiento}</td>
                      <td>{movimiento.saldoDisponible}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No hay movimientos para esta cuenta.</p>
          )}
          <div className="charts-wrapper">
            <div className="chart-container">
              <h3>Gastos por Categorias</h3>
              <PieChartComponent cuentaId={selectedCuenta} />
            </div>
            <div className="line-chart-container">
              <h3>Evolución del Saldo en el Tiempo</h3>
              <LineChartComponent cuentaId={selectedCuenta} />
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <p>Redirigiendo al login...</p>
  )}
</div>
  );
};

export default Home;
