import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext';
import { obtenerMovimientosPorCuenta, deleteMovimiento, updateMovimiento, obtenerDetallesCuenta, obtenerCategorias, obtenerGasto } from './MovimientoService'; 
import { obtenerCuentas } from '../../api';
import { format } from 'date-fns';
import "../Styles/MovimientoCuenta.css";

const MovimientosCuenta = () => {
    const { authData } = useAuth();
    const [cuentas, setCuentas] = useState([]);
    const [selectedCuenta, setSelectedCuenta] = useState('');
    const [movimientos, setMovimientos] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [detallesCuenta, setDetallesCuenta] = useState(null);
    const [categorias, setCategorias] = useState([]);
    const [tiposDeGasto, setTiposDeGasto] = useState({});
    const [selectedMovimiento, setSelectedMovimiento] = useState(null);
    const [nuevoMovimiento, setNuevoMovimiento] = useState({});
    const [mensaje, setMensaje] = useState('');

    useEffect(() => {
        if (authData.token && authData.nombre) {
            obtenerCuentas(authData.nombre, authData.token)
                .then(cuentas => setCuentas(cuentas))
                .catch(error => console.error('Error al obtener cuentas:', error));
            
            obtenerCategorias(authData.token)
                .then(categorias => setCategorias(categorias))
                .catch(error => console.error('Error al obtener categorías:', error));
        }
    }, [authData]);

    useEffect(() => {
        if (selectedCuenta && authData.token) {
            obtenerMovimientosPorCuenta(selectedCuenta, authData.token)
                .then(data => {
                    setMovimientos(data);
                    setTotalPages(Math.ceil(data.length / 10));
                    data.forEach(movimiento => {
                        obtenerGasto(movimiento.tipoGasto_id, authData.token)
                            .then(gasto => {
                                setTiposDeGasto(prev => ({
                                    ...prev,
                                    [movimiento.tipoGasto_id]: gasto.nombre
                                }));
                            })
                            .catch(error => console.error('Error al obtener el tipo de gasto:', error));
                    });
                })
                .catch(error => console.error('Error al obtener los movimientos:', error));

            obtenerDetallesCuenta(selectedCuenta, authData.token)
                .then(detalles => setDetallesCuenta(detalles))
                .catch(error => console.error('Error al obtener detalles de la cuenta:', error));
        }
    }, [selectedCuenta, authData.token]);

    const handleCuentaChange = (event) => {
        setSelectedCuenta(event.target.value);
        setCurrentPage(0);
    };

    const handlePageChange = (newPage) => setCurrentPage(newPage);

    const handleEliminar = (id) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este movimiento?')) {
            deleteMovimiento(id, authData.token)
                .then(() => {
                    setMovimientos(movimientos.filter(movimiento => movimiento.id !== id));
                    setMensaje('Movimiento eliminado con éxito.');
                })
                .catch(error => {
                    console.error('Error al eliminar el movimiento:', error);
                    setMensaje('Error al eliminar el movimiento.');
                });
        }
    };

    const handleActualizar = () => {
        if (selectedMovimiento && nuevoMovimiento) {
            updateMovimiento(selectedMovimiento.id, nuevoMovimiento, authData.token)
                .then(() => {
                    setMovimientos(movimientos.map(movimiento => 
                        movimiento.id === selectedMovimiento.id ? { ...movimiento, ...nuevoMovimiento } : movimiento
                    ));
                    setSelectedMovimiento(null);
                    setNuevoMovimiento({});
                    setMensaje('Movimiento actualizado con éxito.');
                })
                .catch(error => {
                    console.error('Error al actualizar el movimiento:', error);
                    setMensaje('Error al actualizar el movimiento.');
                });
        } else {
            setMensaje('No se ha seleccionado ningún movimiento o los datos del movimiento son inválidos.');
        }
    };

    const handleCancelar = () => {
        setSelectedMovimiento(null);
        setNuevoMovimiento({});
    };

    const formatFecha = (fecha) => format(new Date(fecha), 'yyyy-MM-dd HH:mm:ss');

    const obtenerNombreCategoria = (categoriaId) => {
        const categoria = categorias.find(cat => cat.id === categoriaId);
        return categoria ? categoria.nombre : 'Desconocido';
    };

    const obtenerNombreTipoDeGasto = (tipoGastoId) => tiposDeGasto[tipoGastoId] || 'Desconocido';

    const paginatedMovimientos = movimientos.slice(currentPage * 10, (currentPage + 1) * 10);

    return (
        <div className="movement-container">
            {authData.token ? (
                <div className='movement-content'>
                    <h2>Movimientos por Cuenta</h2>
                    <h3>Seleccione una cuenta</h3>
                    <select id='select-account-movement' onChange={handleCuentaChange} value={selectedCuenta}>
                        <option value="">Selecciona una cuenta</option>
                        {cuentas.map(cuenta => (
                            <option key={cuenta.id} value={cuenta.id}>{cuenta.numeroCuenta} - {cuenta.tipoCuenta}</option>
                        ))}
                    </select>
                    <br />
                    {detallesCuenta && (
                        <div>
                            <h3>Detalles de la Cuenta</h3>
                            <p>Número de Cuenta: {detallesCuenta.numeroCuenta}</p>
                            <p>Saldo: {detallesCuenta.saldoInicial}</p>
                            <p>Estado: {detallesCuenta.estado ? 'Activo' : 'Inactivo'}</p>
                        </div>
                    )}
                    {selectedCuenta && (
                        <div>
                            <h3>Movimientos</h3>
                            {paginatedMovimientos.length > 0 ? (
                                <div className="table-container-movement">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Tipo Movimiento</th>
                                                <th>Valor</th>
                                                <th>Saldo</th>
                                                <th>Número de Cuenta</th>
                                                <th>Categoria</th>
                                                <th>Tipo de Gasto</th>
                                                <th>Descripción</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedMovimientos.map(movimiento => (
                                                <tr key={movimiento.id}>
                                                    <td>{formatFecha(movimiento.fecha)}</td>
                                                    <td>{movimiento.tipoMovimiento}</td>
                                                    <td>{movimiento.valor}</td>
                                                    <td>{movimiento.saldo}</td>
                                                    <td>{movimiento.numeroCuenta}</td>
                                                    <td>{obtenerNombreCategoria(movimiento.categoria_id)}</td>
                                                    <td>{obtenerNombreTipoDeGasto(movimiento.tipoGasto_id)}</td>
                                                    <td>{movimiento.descripcion}</td>
                                                    <td>
                                                        <button onClick={() => {
                                                            setSelectedMovimiento(movimiento);
                                                            setNuevoMovimiento({
                                                                fecha: movimiento.fecha,
                                                                tipoMovimiento: movimiento.tipoMovimiento,
                                                                valor: movimiento.valor,
                                                                saldo: movimiento.saldo
                                                            });
                                                        }}>Actualizar</button>
                                                        <button onClick={() => handleEliminar(movimiento.id)}>Eliminar</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p>No hay movimientos para mostrar.</p>
                            )}
                            <div className="pagination">
                                {currentPage > 0 && (
                                    <button onClick={() => handlePageChange(currentPage - 1)}>Anterior</button>
                                )}
                                {[...Array(totalPages).keys()].map(page => (
                                    <span key={page}>{page + 1}</span>
                                ))}
                                {currentPage < totalPages - 1 && (
                                    <button onClick={() => handlePageChange(currentPage + 1)}>Siguiente</button>
                                )}
                            </div>
                        </div>
                    )}
                    {selectedMovimiento && (
                        <div className='form-update-movement'>
                            <h3>Actualizar Movimiento</h3>
                            <form>
                                <div>
                                    <label>Fecha</label>
                                    <input
                                        type="text"
                                        value={nuevoMovimiento.fecha || ''}
                                        onChange={(e) => setNuevoMovimiento({ ...nuevoMovimiento, fecha: e.target.value })}
                                    />
                                </div>
                                <div>
                                    <label>Tipo Movimiento</label>
                                    <input
                                        type="text"
                                        value={nuevoMovimiento.tipoMovimiento || ''}
                                        onChange={(e) => setNuevoMovimiento({ ...nuevoMovimiento, tipoMovimiento: e.target.value })}
                                    />
                                </div>
                                <div>
                                    <label>Valor</label>
                                    <input
                                        type="text"
                                        value={nuevoMovimiento.valor || ''}
                                        onChange={(e) => setNuevoMovimiento({ ...nuevoMovimiento, valor: e.target.value })}
                                    />
                                </div>
                                <div>
                                    <button type="button" onClick={handleActualizar}>Actualizar</button>
                                    <button type="button" onClick={handleCancelar}>Cancelar</button>
                                </div>
                            </form>
                        </div>
                    )}
                    {mensaje && <p className="mensaje">{mensaje}</p>}
                </div>
            ) : (
                <p>Por favor, inicie sesión para ver los movimientos.</p>
            )}
        </div>
    );
};

export default MovimientosCuenta;
