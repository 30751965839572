import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext';
import { obtenerCuentasPorCliente, eliminarCuenta, actualizarCuenta } from './CuentaService';  // Asegúrate de definir estos métodos en tu servicio
import EditarCuentaForm from './EditarCuentaForm'; // Componente del formulario de edición
import '../Styles/Cuentas.css';
const CuentasCliente = () => {
    const { authData } = useAuth();
    const [cuentas, setCuentas] = useState([]);
    const [editingCuenta, setEditingCuenta] = useState(null);

    useEffect(() => {
        if (authData.token) {
            obtenerCuentasPorCliente(authData.token)
                .then(data => {
                    setCuentas(data);
                })
                .catch(error => {
                    console.error('Error al obtener las cuentas:', error);
                });
        }
    }, [authData.token]);

    const handleEliminar = async (id) => {
        try {
            await eliminarCuenta(id, authData.token);
            setCuentas(cuentas.filter(cuenta => cuenta.id !== id));
        } catch (error) {
            console.error('Error al eliminar la cuenta:', error);
        }
    };

    const handleActualizar = (cuenta) => {
        setEditingCuenta(cuenta);
    };

    const handleActualizarCuenta = async (cuentaActualizada) => {
        try {
            await actualizarCuenta(cuentaActualizada.id, cuentaActualizada, authData.token);
            setCuentas(cuentas.map(cuenta =>
                cuenta.id === cuentaActualizada.id ? cuentaActualizada : cuenta
            ));
            setEditingCuenta(null);
        } catch (error) {
            console.error('Error al actualizar la cuenta:', error);
        }
    };

    return (
        <div className="container-accounts">
            {authData.token ? (
                <div className='main-content'>
                    <h2>Cuentas del Cliente</h2>
                    {cuentas.length > 0 ? (
                        <div className='table-container-accounts'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Número de Cuenta</th>
                                        <th>Banco</th>
                                        <th>Tipo de Cuenta</th>
                                        <th>Saldo</th>
                                        <th>Estado</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cuentas.map(cuenta => (
                                        <tr key={cuenta.id}>
                                            <td>{cuenta.id}</td>
                                            <td>{cuenta.numeroCuenta}</td>
                                            <td>{cuenta.banco}</td>
                                            <td>{cuenta.tipoCuenta}</td>
                                            <td>{cuenta.saldoInicial}</td>
                                            <td>{cuenta.estado ? 'Activo' : 'Inactivo'}</td>
                                            <td>
                                                <button onClick={() => handleActualizar(cuenta)}>Actualizar</button>
                                                <button onClick={() => handleEliminar(cuenta.id)}>Eliminar</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p>No hay cuentas para este cliente.</p>
                    )}
                </div>
            ) : (
                <p>Redirigiendo al login...</p>
            )}
    
            {editingCuenta && (
                <div className="editar-cuenta-form">
                    <EditarCuentaForm 
                        cuenta={editingCuenta} 
                        onClose={() => setEditingCuenta(null)} 
                        onUpdate={handleActualizarCuenta} 
                    />
                </div>
            )}
        </div>
    );
    
};

export default CuentasCliente;
