import React, { useState } from 'react';
import { useAuth } from '../auth/AuthContext';
import { actualizarCuenta } from './CuentaService'; // Asegúrate de definir este método en tu servicio

const EditarCuentaForm = ({ cuenta, onClose, onUpdate }) => {
    const { authData } = useAuth();
    const [numeroCuenta, setNumeroCuenta] = useState(cuenta.numeroCuenta);
    const [tipoCuenta, setTipoCuenta] = useState(cuenta.tipoCuenta);
    const [saldoInicial, setSaldoInicial] = useState(cuenta.saldoInicial);
    const [estado, setEstado] = useState(cuenta.estado);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const cuentaActualizada = {
            ...cuenta,
            numeroCuenta,
            tipoCuenta,
            saldoInicial,
            estado
        };

        try {
            await actualizarCuenta(cuenta.id, cuentaActualizada, authData.token);
            onUpdate(cuentaActualizada);
        } catch (error) {
            console.error('Error al actualizar la cuenta:', error);
        }
        onClose();
    };

    return (
        <div className="editar-cuenta-form">
            <h3>Editar Cuenta</h3>
            <form onSubmit={handleSubmit}>
                <label>
                    Número de Cuenta:
                    <input 
                        type="text" 
                        value={numeroCuenta} 
                        onChange={(e) => setNumeroCuenta(e.target.value)} 
                    />
                </label>
                <label>
                    Tipo de Cuenta:
                    <input 
                        type="text" 
                        value={tipoCuenta} 
                        onChange={(e) => setTipoCuenta(e.target.value)} 
                    />
                </label>
                <label>
                    Saldo Inicial:
                    <input 
                        type="number" 
                        value={saldoInicial} 
                        onChange={(e) => setSaldoInicial(e.target.value)} 
                    />
                </label>
                <label>
                    Estado:
                    <select value={estado} onChange={(e) => setEstado(e.target.value === 'true')}>
                        <option value={true}>Activo</option>
                        <option value={false}>Inactivo</option>
                    </select>
                </label>
                <button type="submit">Actualizar</button>
                <button type="button" className="cancel" onClick={onClose}>Cancelar</button>
            </form>
        </div>
    );
    
};

export default EditarCuentaForm;
