import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate , useLocation} from 'react-router-dom';
import { AuthProvider, useAuth } from './components/auth/AuthContext'; // Asegúrate de que las rutas sean correctas
import Login from './components/auth/Login';
import Home from './pages/Home';
import Sidebar from './components/sidebar'; // Ajusta la ruta según tu estructura
import Register from './components/auth/Register'; // Importa el componente de registro
import CrearCuenta from './components/Cuentas/CrearCuenta';
import Gestion from './components/Movimientos/Gestion';
import MovimientosCuenta from './components/Movimientos/MovimientosCuenta';
import CuentasCliente from './components/Cuentas/CuentasCliente';
import NotFound from './pages/NotFound';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/gestion" element={<PrivateRoute><Gestion /></PrivateRoute>} />
            <Route path='/crear-cuenta' element={<PrivateRoute><CrearCuenta/></PrivateRoute>}/>
            <Route path='/movimientos-cuenta' element={<PrivateRoute><MovimientosCuenta/></PrivateRoute>} />
            <Route path='/cuentas-clientes' element={<PrivateRoute><CuentasCliente/></PrivateRoute>} />
            {/* Añade más rutas aquí según sea necesario */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  );
};

const Layout = ({ children }) => {
  const { authData } = useAuth();
  const location = useLocation(); // Obtén la ruta actual

  // Verifica si la ruta actual es '/login' o '/register'
  const shouldShowSidebar = authData.token && !['/', '/register'].includes(location.pathname);

  return (
    <div className="main-container">
            {shouldShowSidebar && <Sidebar />}
            <div className="content">
                {children}
            </div>
        </div>
  );
};

const PrivateRoute = ({ children }) => {
  const { authData } = useAuth();
  return authData.token ? children : <Navigate to="/login" />;
};

export default App;
