import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale } from 'chart.js';
import { format } from 'date-fns';
import { useAuth } from '../auth/AuthContext'; // Asegúrate de importar esto
import './LineChartComponent.css'; // Importa el archivo CSS para estilos

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);
const API_URL = 'https://moneyapi.edwarhercules.site'; // Reemplaza con la URL de tu backend
// const API_URL = 'http://localhost:8081';

const LineChartComponent = ({ cuentaId }) => {
  const [movimientoData, setMovimientoData] = useState([]);
  const { authData } = useAuth(); // Obtén el token de autenticación desde el contexto

  useEffect(() => {
    if (cuentaId) {
      axios.get(`${API_URL}/api/movimientos/lineal/${cuentaId}`, {
        headers: {
          'Authorization': `Bearer ${authData.token}`, // Incluye el token en los encabezados
        },
      })
      .then(response => {
        console.log('Datos del gráfico lineal:', response.data); // Verifica los datos aquí
        setMovimientoData(response.data);
      })
      .catch(error => {
        console.error('Error al obtener los datos del gráfico lineal:', error);
      });
    }
  }, [cuentaId, authData.token]); // Asegúrate de incluir authData.token en las dependencias

  const chartData = {
    labels: movimientoData.map(item => format(new Date(item.fecha), 'dd/MM/yyyy HH:mm:ss')), // Formatea la fecha y hora
    datasets: [
      {
        label: 'Saldo en el tiempo',
        data: movimientoData.map(item => item.saldo),
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        display: window.innerWidth > 500, // Mostrar eje x solo en pantallas grandes
      },
      /*y: {
        display: window.innerWidth > 480, // Mostrar eje y solo en pantallas grandes
      },*/
    },

  };

  return (
    <div className="line-chart-container">
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default LineChartComponent;
