import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext';
import { obtenerCuentas } from '../../api';
import { crearMovimiento, obtenerCategorias, obtenerTiposGasto } from './MovimientoService';
import { Link } from 'react-router-dom';
import "../Styles/Gestion.css";

const Gestion = () => {
    const { authData } = useAuth();
    const [cuentas, setCuentas] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [tiposGasto, setTiposGasto] = useState([]);
    const [selectedCuenta, setSelectedCuenta] = useState('');
    const [nuevoMovimiento, setNuevoMovimiento] = useState({
        tipoMovimiento: '',
        valor: '',
        cuentaDestinoId: '',
        categoriaId: '',
        tipoGastoId: '',
        descripcion: ''
    });

    useEffect(() => {
        if (authData.token && authData.nombre) {
            obtenerCuentas(authData.nombre, authData.token)
                .then(cuentas => {
                    setCuentas(cuentas);
                })
                .catch(error => {
                    console.error('Error al obtener cuentas:', error);
                });

            obtenerCategorias(authData.token)
                .then(categorias => {
                    setCategorias(categorias);
                })
                .catch(error => {
                    console.error('Error al obtener categorías:', error);
                });
        }
    }, [authData]);

    const handleCuentaChange = (event) => {
        setSelectedCuenta(event.target.value);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNuevoMovimiento(prevState => ({
            ...prevState,
            [name]: value,
        }));

        if (name === 'categoriaId') {
            obtenerTiposGasto(value, authData.token)
                .then(tiposGasto => {
                    setTiposGasto(tiposGasto);
                    setNuevoMovimiento(prevState => ({
                        ...prevState,
                        tipoGastoId: '' // Reset tipoGastoId when categoriaId changes
                    }));
                })
                .catch(error => {
                    console.error('Error al obtener tipos de gasto:', error);
                });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!nuevoMovimiento.tipoMovimiento) {
            alert('Por favor, selecciona un tipo de movimiento.');
            return;
        }

        if (!nuevoMovimiento.valor) {
            alert('Por favor, ingresa el valor del movimiento.');
            return;
        }

        // Determinar cuenta destino e origen según el tipo de movimiento
        let cuentaDestinoId = null;
        let cuentaOrigenId = selectedCuenta;

        if (nuevoMovimiento.tipoMovimiento === 'Transferencia') {
            if (!nuevoMovimiento.cuentaDestinoId) {
                alert('Por favor, selecciona una cuenta destino para la transferencia.');
                return;
            }
            cuentaDestinoId = nuevoMovimiento.cuentaDestinoId;
        } else if (nuevoMovimiento.tipoMovimiento === 'Deposito') {
            cuentaDestinoId = selectedCuenta;
        }

        const movimiento = {
            cuentaOrigenId,
            cuentaDestinoId,
            tipoMovimiento: nuevoMovimiento.tipoMovimiento,
            valor: parseFloat(nuevoMovimiento.valor),
            categoriaId: nuevoMovimiento.categoriaId ? parseInt(nuevoMovimiento.categoriaId) : null,
            tipoGastoId: nuevoMovimiento.tipoGastoId ? parseInt(nuevoMovimiento.tipoGastoId) : null,
            descripcion: nuevoMovimiento.descripcion
        };

        // Log para verificar los datos antes de enviarlos
        console.log('Datos del movimiento a enviar:', movimiento);

        crearMovimiento(movimiento, authData.token)
            .then(response => {
                alert('Movimiento creado con éxito.');
                // Limpiar formulario después de la creación
                setNuevoMovimiento({
                    tipoMovimiento: '',
                    valor: '',
                    cuentaDestinoId: '',
                    categoriaId: '',
                    tipoGastoId: '',
                    descripcion: ''
                });
                setSelectedCuenta(''); // Limpiar selección de cuenta
                setTiposGasto([]); // Limpiar tipos de gasto
                console.log('Respuesta del servidor:', response);
            })
            .catch(error => {
                console.error('Error al crear el movimiento:', error);
                alert('Error al crear el movimiento.');
            });
    };

    return (
        <div className="gestion-container">
            <div className='gestion-content'>

                <h2>Gestión de Movimientos</h2>

                <div>
                    <label htmlFor="cuenta">Selecciona una Cuenta:</label>
                    <select
                        id="cuenta"
                        value={selectedCuenta}
                        onChange={handleCuentaChange}
                    >
                        <option value="">Selecciona una cuenta</option>
                        {cuentas.map(cuenta => (
                            <option key={cuenta.id} value={cuenta.id}>{cuenta.numeroCuenta} - {cuenta.tipoCuenta}</option>
                        ))}
                    </select>
                </div>

                {selectedCuenta && (
                    <div className='form-new-movement'>
                        <h3>Crear Nuevo Movimiento</h3>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="tipoMovimiento">Tipo de Movimiento:</label>
                                <select
                                    id="tipoMovimiento"
                                    name="tipoMovimiento"
                                    value={nuevoMovimiento.tipoMovimiento}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Selecciona un tipo</option>
                                    <option value="Deposito">Depósito</option>
                                    <option value="Retiro">Retiro</option>
                                    <option value="Transferencia">Transferencia</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="valor">Valor:</label>
                                <input
                                    type="number"
                                    id="valor"
                                    name="valor"
                                    value={nuevoMovimiento.valor}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            {nuevoMovimiento.tipoMovimiento === 'Transferencia' && (
                                <div>
                                    <label htmlFor="cuentaDestino">Cuenta Destino:</label>
                                    <select
                                        id="cuentaDestino"
                                        name="cuentaDestinoId"
                                        value={nuevoMovimiento.cuentaDestinoId}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Selecciona una cuenta destino</option>
                                        {cuentas.filter(cuenta => cuenta.id !== selectedCuenta).map(cuenta => (
                                            <option key={cuenta.id} value={cuenta.id}>{cuenta.numeroCuenta}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <div>
                                <label htmlFor="categoriaId">Categoría:</label>
                                <select
                                    id="categoriaId"
                                    name="categoriaId"
                                    value={nuevoMovimiento.categoriaId}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Selecciona una categoría</option>
                                    {categorias.map(categoria => (
                                        <option key={categoria.id} value={categoria.id}>{categoria.nombre}</option>
                                    ))}
                                </select>
                            </div>
                            {tiposGasto.length > 0 && (
                                <div>
                                    <label htmlFor="tipoGastoId">Tipo de Gasto:</label>
                                    <select
                                        id="tipoGastoId"
                                        name="tipoGastoId"
                                        value={nuevoMovimiento.tipoGastoId}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Selecciona un tipo de gasto</option>
                                        {tiposGasto.map(tipoGasto => (
                                            <option key={tipoGasto.id} value={tipoGasto.id}>{tipoGasto.nombre}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <div>
                                <label htmlFor="descripcion">Descripción:</label>
                                <textarea
                                    id="descripcion"
                                    name="descripcion"
                                    value={nuevoMovimiento.descripcion}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <button type="submit">Crear Movimiento</button>
                        </form>
                    </div>
                )}

                <Link to="/home" className="back-link">Volver al Inicio</Link>
            </div>
        </div>
    );
};

export default Gestion;
