import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useAuth } from '../auth/AuthContext';
import './PieChartComponent.css';

ChartJS.register(ArcElement, Tooltip, Legend);
const API_URL = 'https://moneyapi.edwarhercules.site'; // Reemplaza con la URL de tu backend
// const API_URL = 'http://localhost:8081';

const PieChartComponent = ({ cuentaId }) => {
  const [gastoData, setGastoData] = useState([]);
  const { authData } = useAuth();

  useEffect(() => {
    if (cuentaId) {
      axios.get(`${API_URL}/api/movimientos/pastel/${cuentaId}`, {
        headers: {
          'Authorization': `Bearer ${authData.token}`,
        },
      })
      .then(response => {
        console.log('Datos del gráfico:', response.data);
        setGastoData(response.data);
      })
      .catch(error => {
        console.error('Error al obtener los datos del gráfico:', error);
      });
    }
  }, [cuentaId, authData.token]);

  const chartData = {
    labels: gastoData.map(item => item.tipoGasto),
    datasets: [
      {
        data: gastoData.map(item => item.totalValor),
        backgroundColor: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA336A', '#FF6361'],
        hoverBackgroundColor: ['#0077e5', '#00b38c', '#e5b100', '#e57b00', '#993366', '#e55a5a'],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
            const value = context.raw;
            const percentage = ((value / total) * 100).toFixed(2) + '%';
            return `${context.label}: ${value} (${percentage})`;
          },
        },
        displayColors: false,
        backgroundColor: '#FFF', // Fondo del tooltip
        titleColor: '#000',      // Color del título del tooltip
        bodyColor: '#000',       // Color del texto del tooltip
        borderColor: '#CCC',
        borderWidth: 1,
        titleFont: {
          weight: 'bold',
          size: 14,             // Tamaño del título del tooltip
        },
        bodyFont: {
          size: 14,             // Tamaño del texto del tooltip
        },
      },
      legend: {
        labels: {
          color: '#eae1e1',        // Color de las etiquetas de la leyenda
          font: {
            size: 14,           // Tamaño del texto de las etiquetas de la leyenda
          },
        },
      },
    },
  };

  return (
    <div className="chart-container">
      <Pie data={chartData} options={chartOptions} />
    </div>
  );
};

export default PieChartComponent;
