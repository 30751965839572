import React, { useState } from 'react';
import { useAuth } from '../auth/AuthContext';
import CuentaService from './CuentaService';
import { useNavigate } from 'react-router-dom';
import "../Styles/CrearCuenta.css";

// Lista de bancos en Honduras
const bancos = [
  "BAC Credomatic",
  "Banco del Pais",
  "Banco Atlántida",
  "Banco de Occidente",
  "BANRURAL",
  "BANCAFE",
  "Banco Nacional de Agricultura",
  "Banco Ficohsa",
  "Banco HSBC",
  "Banco Popular",
  "Banco ProCredit",
  "Banco Lafise",
  "Banco Citibank",
  "Banco Industrial"
];

// Lista de tipos de cuentas
const tiposDeCuenta = [
  "Cuenta Corriente",
  "Cuenta de Ahorros",
  "Cuenta de Inversión",
  "Cuenta de Nómina",
  "Cuenta Infantil",
  "Cuenta Empresarial",
  "Cuenta de Credito"
];

const CrearCuenta = () => {
  const [numeroCuenta, setNumeroCuenta] = useState('');
  const [banco, setBanco] = useState('');
  const [tipoCuenta, setTipoCuenta] = useState('');
  const [saldoInicial, setSaldoInicial] = useState('');
  const { authData } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nuevaCuenta = {
      numeroCuenta,
      banco, // Incluye el banco en el objeto de la nueva cuenta
      tipoCuenta,
      saldoInicial,
    };
    try {
      await CuentaService.crearCuenta(authData.nombre, nuevaCuenta, authData.token);
      alert('Cuenta creada con éxito');
      navigate('/home');
    } catch (error) {
      console.error('Error al crear la cuenta', error);
      alert('Hubo un error al crear la cuenta');
    }
  };

  return (
    <div className="crear-cuenta-container">
      <h2>Crear Nueva Cuenta</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Número de Cuenta:</label>
          <input
            type="text"
            value={numeroCuenta}
            onChange={(e) => setNumeroCuenta(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Banco:</label>
          <select
            value={banco}
            onChange={(e) => setBanco(e.target.value)}
            required
          >
            <option value="">Selecciona un banco</option>
            {bancos.map((banco, index) => (
              <option key={index} value={banco}>{banco}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Tipo de Cuenta:</label>
          <select
            value={tipoCuenta}
            onChange={(e) => setTipoCuenta(e.target.value)}
            required
          >
            <option value="">Selecciona un tipo de cuenta</option>
            {tiposDeCuenta.map((tipo, index) => (
              <option key={index} value={tipo}>{tipo}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Saldo Inicial:</label>
          <input
            type="number"
            value={saldoInicial}
            onChange={(e) => setSaldoInicial(e.target.value)}
            required
          />
        </div>
        <button type="submit">Crear Cuenta</button>
      </form>
    </div>
  );
};

export default CrearCuenta;
