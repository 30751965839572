import axios from 'axios';

const API_URL = 'https://moneyapi.edwarhercules.site/api/movimientos';
// const API_URL = 'http://localhost:8081/api/movimientos';

export const getAllMovimientos = async () => {
    try {
        const response = await axios.get(API_URL);
        return response.data;
    } catch (error) {
        console.error('Error fetching movimientos:', error);
        throw error;
    }
};

export const crearMovimiento = async (movimientoDTO, token) => {
    try {
        const response = await axios.post(`${API_URL}/crear`, movimientoDTO, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error al crear el movimiento:', error);
        throw error;
    }
};

export const getMovimientoById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching movimiento by ID:', error);
        throw error;
    }
};

export const updateMovimiento = async (id, movimientoDTO, token) => {
  try {
      const response = await fetch(`${API_URL}/actualizar/${id}`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(movimientoDTO),
      });

      if (!response.ok) {
          throw new Error('Error al actualizar el movimiento');
      }

      return response.json();
  } catch (error) {
      console.error('Error al actualizar el movimiento:', error);
      throw error;
  }
};


export const deleteMovimiento = async (id, token) => {
  try {
      const response = await fetch(`${API_URL}/eliminar/${id}`, {
          method: 'DELETE',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
      });

      if (!response.ok) {
          throw new Error('Error al eliminar el movimiento');
      }
  } catch (error) {
      console.error('Error al eliminar el movimiento:', error);
      throw error;
  }
};


export const getUltimosMovimientos = async (cuentaId, token) => {
    try {
      const response = await axios.get(`${API_URL}/ultimos/${cuentaId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error al obtener los movimientos:', error);
      throw error;
    }
};
  
export const obtenerMovimientosPorCuenta = async (cuentaId, token, page, size) => {
    try {
        const response = await axios.get(`${API_URL}/allMovimientos/${cuentaId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                page: page,
                size: size
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener los movimientos:', error);
        throw error;
    }
};


export const obtenerDetallesCuenta = async (cuentaId, token) => {
    const response = await fetch(`/api/cuentas/${cuentaId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  
    if (!response.ok) {
      throw new Error('Error al obtener los detalles de la cuenta');
    }
  
    const data = await response.json();
    return data;
};


export const obtenerCategorias = async (token) => {
    const response = await fetch(`${API_URL}/categorias`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    });
    if (!response.ok) {
        throw new Error('Error obteniendo las categorías');
    }
    return response.json();
};

export const obtenerTiposGasto = async (categoriaId, token) => {
    const response = await fetch(`${API_URL}/tipoGasto/${categoriaId}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    });
    if (!response.ok) {
        throw new Error('Error obteniendo los tipos de gasto');
    }
    return response.json();
};

export const obtenerGasto = async (id, token) => {
    const response = await fetch(`${API_URL}/gasto/${id}` , {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type' : 'application/json',
        }
    });
    if (!response.ok) {
        throw new Error('Error obteniendo el gasto');
    }
    return response.json();
}